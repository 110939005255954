<template>
  <div>
    <div class="chooseroombox mb-2" v-if="device === 'mobile'">
      <button class="js-del-row" v-if="index > 0" @click="remove"><i class="far fa-window-close"></i></button>
      <h2 class="mb-0">{{$t("product-page.room")}} {{ index + 1 }} : {{ $t("product-page.choose-room") }}</h2>
      <h6 class="mt-0" style="color:red;"><strong>{{$t("product-page.available-rooms")}}: {{ availableRooms }}</strong></h6>
      <div class="row row-0 mx-n2">
        <div class="col-6">
          <div class="form-group">
            <b-form-select
              v-model="currentTypeCode"
              :options="optionByRoomTypes"
            ></b-form-select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <b-form-select
              v-model="countOfBaby"
              :options="babies"
            ></b-form-select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <b-form-select
              v-model="currentBasis"
              :options="optionBasis"
            ></b-form-select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <b-form-select
              v-model="currentClassCode"
              :options="optionByRoomClasses"
            ></b-form-select>
          </div>
        </div>
        <div class="col-12 mt-1" v-if="showBabyNotice">
            <p>{{"Please pay attention! Addition of a baby to the room is only with the approval of the reservation center. Unfortunately, not all rooms are suitable for baby addition."}}</p>
        </div>
        <!-- <div class="col-4">
        </div> -->
        <div class="col-12 ptag">
          <p class="pl-2 mb-1">
            {{ $t("product-page.price-room") }}
          </p>
          <p class="mb-2" v-if="countOfBaby === 0">({{ $t("product-page.no-babies") }})</p>
          <p class="mb-2" v-if="countOfBaby && countOfBaby === 1">({{ $t("product-page.infant") }} {{ countOfBaby }})</p>
          <p class="mb-2" v-if="countOfBaby && countOfBaby > 1">({{ $t("product-page.infants") }} {{ countOfBaby }})</p>
          <p class="mb-2 p-price">
            <strong class="mx-3">${{ currentPrice }}</strong>
            <s v-if="!isOdysseySite">${{ oldPrice }}</s>
          </p>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="device === 'desktop'">
      <h5 class="mb-0">
        <strong>{{ $t("product-page.room") }} {{ $t(`product-page.ordered-number[${index}]`)}} : {{ roomClassName }}, {{ personString }}</strong>
      </h5>
      <h5 style="color:red;"><strong>{{$t("product-page.available-rooms")}}: {{ availableRooms }}</strong></h5>
      <table class="table">
        <thead>
          <tr>
            <th style="width:25%;">{{ $t("product-page.room-type") }}</th>
            <!-- <th>{{ $t("product-page.babies") }}</th> -->
            <th style="width:45%;">{{ $t("product-page.room-class") }}</th>
            <th style="width:30%;">{{ $t("product-page.basis") }}</th>
            <!-- <th>{{ $t("product-page.price") }}</th> -->
          </tr>
        </thead>
        <tr>
          <td>
            <b-form-select
              v-model="currentTypeCode"
              :options="optionByRoomTypes"
            ></b-form-select>
          </td>
          <!-- <td>
            <b-form-select
              v-model="countOfBaby"
              :options="babies"
            ></b-form-select>
          </td> -->
          <td>
            <b-form-select
              v-model="currentClassCode"
              :options="optionByRoomClasses"
            ></b-form-select>
          </td>
          <td>
            <b-form-select
              v-model="currentBasis"
              :options="optionBasis"
            ></b-form-select>
          </td>
          <!-- <td>
            <b-spinner v-if="priceLoading"></b-spinner>
            <div v-else>
              <span>${{ currentPrice }}</span
              ><br /><s>${{ oldPrice }}</s>
            </div>
          </td> -->
        </tr>
        <tr>
          <th class="align-middle">{{ $t('product-page.babies') }}:</th>
          <td colspan="2"> <b-form-select v-model="countOfBaby" :options="babies"></b-form-select></td>
          <td></td>
        </tr>
        <tr v-if="showBabyNotice">
          <td colspan="3">
            <p>{{"אנא שים לב! תוספת תינוק לחדר הינה באישור מרכז ההזמנות בלבד. לצערינו, לא כל החדרים מתאימים לתוספת תינוק."}}</p>
          </td>
        </tr>
      </table>

      <button class="js-del-row" v-if="index > 0" @click="remove">
        <i class="far fa-window-close"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    BFormSelect,
    // BSpinner,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: null,
    },
    hotels: {
      type: Array,
      default: () => [],
    },
    supplier: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      optionByRoomTypes: [],
      optionsBasis: [],
      currentClassCode: '',
      currentTypeCode: '',
      currentBasis: '',
      roomBasisName: '',
      countOfBaby: 0,
      babies: [
        { value: 0, text: this.$t('product-page.babies') },
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
      ],
      roomClassName: '',
      currentPrice: 0,
      oldPrice: 0,
      priceLoading: false,
      query: this.$route.query,

      availableRooms: 0,

      // minPriceHotel: null,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      isMemberLogin: 'IS_AUTHORIZED',
      memberDiscountPercent: 'GET_DISCOUNT_PERCENT',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
    }),
    optionByRoomClasses() {
      if (this.hotels.length === 0) {
        return [];
      }
      const roomCodes = [
        ...new Set(this.hotels.map((item) => item.roomCode)),
      ];
      const options = roomCodes.map((elm) => ({ value: elm, text: this.hotels.find((elem) => elem.roomCode === elm).roomName }));
      this.setRoomClassCode(options);
      return options;
    },
    optionBasis() {
      if (this.hotels.length === 0) {
        return [];
      }
      const { currentClassCode } = this;
      // const { priceList } = this.query;
      // const basis = (priceList) ? [
      //   ...new Set(this.hotels.filter((elm) => elm.priceList === priceList).map((elm) => elm.basis)),
      // ] : [
      //   ...new Set(this.hotels.map((elm) => elm.basis)),
      // ];
      const basis = (currentClassCode) ? [
        ...new Set(this.hotels.filter((elm) => elm.roomCode === currentClassCode).map((elm) => elm.basis)),
      ] : [
        ...new Set(this.hotels.map((elm) => elm.basis)),
      ];
      const options = basis.map((elm) => ({ value: elm, text: this.$t(`basis-name.${elm.toLowerCase()}`) }));
      this.setBasis(options, this.currentBasis === '');
      return options;
    },
    personString() {
      const adult = Number((this.currentTypeCode.split(',')[0]));
      const child = Number(this.currentTypeCode.split(',')[1]);
      const infant = Number(this.countOfBaby);
      let str = '';
      if (adult === 1) {
        str = `${adult} ${this.$t('product-page.adult')}`;
      } else {
        str = `${adult} ${this.$t('product-page.adults')}`;
      }
      if (child === 1) {
        str += ` + ${child} ${this.$t('product-page.child')}`;
      } else if (child > 1) {
        str += ` + ${child} ${this.$t('product-page.children')}`;
      }
      if (infant === 1) {
        str += ` + ${infant}  ${this.$t('product-page.infant')}`;
      } else if (infant > 1) {
        str += ` + ${infant}  ${this.$t('product-page.infants')}`;
      }
      return str;
    },
    roomTypeArray() {
      return [
        ['2,0', 'couple'],
        ['2,1', 'couple-child'],
        ['2,2', 'couple-2-child'],
        ['2,3', 'couple-3-child'],
        ['2,4', 'couple-4-child'],
        ['1,0', 'single'],
        ['1,1', 'single-child'],
        ['1,2', 'single-2-child'],
        ['3,0', 'three-adult'],
        ['3,1', 'three-adult-child'],
        ['3,2', 'three-adult-2-child'],
        ['4,0', 'four-adult'],
        ['5,0', 'five-adult'],
        ['6,0', 'six-adult'],
        ['7,0', 'seven-adult'],
        ['8,0', 'eight-adult'],
      ];
    },
    showBabyNotice() {
      return this.countOfBaby > 0 && this.hotels.length > 0 && this.hotels[0].supplierCode === 'ATLANTIS';
    },
    manualDiscount() {
      return Number(this.query.manualDiscount) || 0;
    },
  },
  watch: {
    currentTypeCode() {
      this.fetchHotels();
    },
    countOfBaby() {
      this.fetchHotels();
    },
    currentClassCode() {
      this.roomClassName = this.getRoomClassName();
      setTimeout(() => { this.updateItem(); }, 500);
    },
    currentBasis() {
      this.roomBasisName = this.optionBasis.find((el) => el.value === this.currentBasis)?.text;
      this.updateItem();
    },
    memberDiscountPercent() {
      this.updateItem();
    },
    priceLoading() {
      this.$emit('setPriceLoading', this.priceLoading);
    },
    lang() {
      this.getRoomTypeOptions();
      this.babies = [
        { value: 0, text: this.$t('product-page.babies') },
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
      ];
    },
    hotels() {
      if (this.hotels.length === 0) {
        this.$bvModal.msgBoxOk(
          this.$t('product-page.no-hotel-message'),
          {
            title: this.$t('product-page.expire-title'),
            bodyClass: 'text-center',
            okVariant: 'success',
            okTitle: this.$t('product-page.ok'),
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          },
        );
      }
      this.updateItem();
    },
  },
  beforeMount() {
    this.getRoomTypeOptions();
    this.countOfBaby = Number(this.query.infant);
    this.currentTypeCode = `${this.query.adult},${this.query.child}`;
  },
  methods: {
    async fetchHotels() {
      const adult = this.currentTypeCode.split(',')[0], child = this.currentTypeCode.split(',')[1];
      const body = {
        city: this.query.city,
        hotelCode: String(this.query.hotelCode),
        checkIn: this.query.checkIn,
        checkOut: this.query.checkOut,
        adult: Number(adult),
        child: Number(child),
        infant: Number(this.countOfBaby),
        lang: this.query.lang,
        includeFlight: null,
        suppliers: (this.query.suppliers) ? [this.query.suppliers] : [],
        returnLog: null,
      };

      this.priceLoading = true;
      const packages = await this.$store.dispatch('FETCH_HOTELS_BY_OCCUPATION', body);
      this.$emit('changedRoomType', this.index, packages);
      this.priceLoading = false;
    },
    setRoomClassCode(option) {
      // this.minPriceHotel = this.getMinHotel();
      // const inx = option.findIndex((elm) => elm.value === this.minPriceHotel.roomCode);
      let inx = option.findIndex((elm) => elm.value === this.query.roomCode);
      if (inx === -1) inx = 0;
      this.currentClassCode = option?.[inx]?.value;
    },
    setBasis(option, isFirst) {
      if (!isFirst) {
        if (option.findIndex((elm) => elm.value === this.currentBasis) === -1) {
          this.currentBasis = option[0].value;
        }
        return;
      }
      const inx = option.findIndex((elm) => elm.value === this.query.basisCode);
      this.currentBasis = option?.[inx]?.value || '';
    },
    getRoomTypeOptions() {
      this.optionByRoomTypes = this.roomTypeArray.map((item) => ({ value: item[0], text: this.$t(`room-type-list.${item[1]}`) }));
    },
    getRoomClassName() {
      return this.optionByRoomClasses.find((elm) => (elm.value === this.currentClassCode))?.text || '';
    },
    remove() {
      this.$emit('closed', this.index);
    },
    async updateItem() {
      // const priceList = this.query.priceList || null;
      // let hotel = null;
      // if (priceList) {
      //   hotel = this.hotels.find((elm) => (elm.roomCode === this.currentClassCode && elm.basis === this.currentBasis && elm.priceList === priceList));
      // } else {
      //   hotel = this.hotels.find((elm) => (elm.roomCode === this.currentClassCode && elm.basis === this.currentBasis && (elm.priceList === null || elm.priceList === '')));
      // }
      const hotels = this.hotels.filter((elm) => (elm.roomCode === this.currentClassCode && elm.basis === this.currentBasis));
      const hotel = this.getMinPriceHotel(hotels);
      this.availableRooms = hotel?.avail || 0;

      const oPrice = hotel?.totalAfterDiscounted || NaN;
      const isAtlantis = hotel?.matchingDiscInfo?.atlantisDiscounted || false;
      const isCreditCardRemarkHotel = !!hotel?.matchingDiscInfo?.creditCardRemark || false;
      const price = (isAtlantis || isCreditCardRemarkHotel) ? oPrice : oPrice * (1 - this.memberDiscountPercent / 100);
      let roomPrice = (this.isMemberLogin) ? Math.round(price) : Math.round(oPrice);
      if (this.isOdysseySite) {
        roomPrice = Math.round(hotel.total * (1 - this.manualDiscount / 100));
      }
      const roomOccupString = this.roomTypeArray.find((item) => (item[0] === this.currentTypeCode))[1];

      const updateData = {
        index: this.index,
        searchGuid: hotel?.searchGuid,
        roomRateGuid: hotel?.roomRateGuid,
        roomClass: this.currentClassCode,
        roomClassName: this.roomClassName,
        roomBasis: this.currentBasis,
        roomBasisName: this.roomBasisName, // this.optionBasis.find((el) => el.value === this.currentBasis)?.text,
        adult: this.currentTypeCode.split(',')[0],
        child: this.currentTypeCode.split(',')[1],
        infant: this.countOfBaby,
        price: roomPrice, // hotel?.totalAfterDiscounted || NaN,
        oldPrice: hotel?.total || NaN,
        token: hotel?.token || NaN,
        discountPercent: hotel?.discountPercent || NaN,
        priceList: hotel?.priceList || NaN,
        roomOccupCode: hotel?.roomOccupCode || this.currentTypeCode,
        roomOccupString,
        roomTypeName: this.$t(`room-type-list.${roomOccupString}`),
        category: hotel?.category || null,
        validation: !!hotel,
        net: hotel?.net || 0,
        currencyCode: hotel?.cc,
        totalInOrgCur: hotel?.totalInOrgCur,
      };

      this.currentPrice = (hotel) ? updateData.price : NaN;
      // eslint-disable-next-line no-nested-ternary
      this.oldPrice = (!hotel) ? NaN : (updateData.oldPrice === updateData.price) ? (updateData.oldPrice * 1.1).toFixed(0) : updateData.oldPrice;
      this.$emit('update', updateData);
    },
    // getMinHotel() {
    //   return this.hotels.reduce((prev, curr) => (prev.totalAfterDiscounted < curr.totalAfterDiscounted ? prev : curr));
    // },
    getMinPriceHotel(hs) {
      return hs?.reduce((prev, curr) => (prev.totalAfterDiscounted < curr.totalAfterDiscounted ? prev : curr));
    },
  },
};
</script>

<style scoped>
s {
  opacity: 0.5;
}
.ptag{
  text-align: center;
}
.p-price {
  display: inline-flex;
  align-items: baseline;
}
.table > thead > tr > th {
  padding: 12px 3px;
}
.table td {
  padding: 0.75rem 0.1rem;
  vertical-align: middle;
  text-align: center;
}
.custom-select {
  padding: 0.375rem 1.55rem 0.375rem 1.05rem;
}
@media (max-width: 479px) {
  .card-body {
    padding: 0.25rem;
  }
  .flight-details-leftbox .table > tbody > tr > td,
  .flight-details-leftbox .table > tbody > tr > th,
  .flight-details-leftbox .table > tfoot > tr > td,
  .flight-details-leftbox .table > tfoot > tr > th,
  .flight-details-leftbox .table > thead > tr > td,
  .flight-details-leftbox .table > thead > tr > th {
    padding: 4px;
  }
}
.form-check-input {
  right: -15px;
}
</style>
